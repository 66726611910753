import React from "react"
import { Container, Row, Col, Button, Accordion } from "react-bootstrap"
import Title from "../title"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-intl"
import { useIntl } from "gatsby-plugin-react-intl"

import * as styles from "../../styles/components/Patient/patient-care.module.scss"

export default function PatientCare() {
  const intl = useIntl()
  return (
    <div>
      <Title title={intl.formatMessage({ id: "patientcare" })} />
      <Container fluid>
        <Row xs={1} md={2}>
          <Col className={styles.wrapper}>
            <div className={`${styles.content} p-5`}>
              <h1 className={styles.title}>
                {intl.formatMessage({ id: "patientcareTitle1" })}
              </h1>
              <p className={`${styles.text} my-4 `}>
                {intl.formatMessage({ id: "patientcare1" })}
              </p>
              <Link to="/locations">
                <Button variant="purple">
                  {intl.formatMessage({ id: "patientcareButton1" })}
                </Button>
              </Link>
            </div>
          </Col>

          <Col className="p-0">
            <StaticImage
              src="../../images/patient-care.jpg"
              alt="patient care"
              layout="full_width"
              className={styles.img}
            />
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row md={2} className="my-5 py-5 g-5 align-items-center">
          <Col className="px-5">
            <h3 className={`${styles.title2} mb-5`}>
              {intl.formatMessage({ id: "patientcareTitle2" })}{" "}
            </h3>
            <p>
              <em> {intl.formatMessage({ id: "patientcare2" })}</em>
            </p>
          </Col>

          <Col className="px-5 text-center">
            <StaticImage
              src="../../images/patient-care-2.webp"
              alt="patient care"
              layout="fixed"
              className="mx-auto mb-3"
            />

            <Link to="/about">
              <Button variant="warning" size="lg" className={styles.btn}>
                {intl.formatMessage({ id: "patientcareButton2" })}
              </Button>
            </Link>
          </Col>
        </Row>

        {/* Information Section */}

        <Row xs={1} md={2} className="my-5 py-5 g-5 align-items-center">
          <Col className="px-5">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle3" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare3" })}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle4" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare4" })}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle5" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare5" })}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle6" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare6" })}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle6" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare7" })}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle8" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare8" })}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  {intl.formatMessage({ id: "patientcareTitle9" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "patientcare9" })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col className="px-5">
            <div className={styles.purple}>
              <p>{intl.formatMessage({ id: "patientcare10" })}</p>
              <p>{intl.formatMessage({ id: "patientcare11" })}</p>
              <p className="mb-4">
                {" "}
                {intl.formatMessage({ id: "patientcareButton3" })}{" "}
                <a href="mailto:kidsandteens@ktdoctor.com">
                  kidsandteens@ktdoctor.com
                </a>{" "}
                {intl.formatMessage({ id: "patientcare12" })}
              </p>

              <a href="mailto:kidsandteens@ktdoctor.com">
                <Button variant="tertiary">
                  {intl.formatMessage({ id: "patientcareButton3" })}
                </Button>
              </a>
            </div>
          </Col>
        </Row>

        <Row xs={1} lg={3} className="p-5 g-5">
          <Col>
            <h3>{intl.formatMessage({ id: "patientcareTitle2" })}</h3>
            <p>{intl.formatMessage({ id: "patientcare13" })}</p>
          </Col>

          <Col>
            <h3>{intl.formatMessage({ id: "patientcareTitle11" })}</h3>
            <p>{intl.formatMessage({ id: "patientcare14" })}</p>
          </Col>

          <Col>
            <h3>{intl.formatMessage({ id: "patientcareTitle12" })}</h3>
            <p>{intl.formatMessage({ id: "patientcare15" })}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
