import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Patient from '../components/Patient/patient-care'

const PatientCare = () => (
    <Layout>
        <Seo title="Patient Care" description="Kids &amp; Teens Medical Group provides digital registration to help you prepare for your upcoming appointment. For questions or to schedule an appointment, contact the Kids &amp; Teens Medical Group team at +18182449595 or visit us at 1530 E Chevy Chase Dr, Ste 202, Glendale, CA 91206" />
        <Patient />
    </Layout>
)

export default PatientCare
